import { tzKL } from "@/lib/date-fns-util";
import dayjs from "@/lib/dayjs";
import { unique } from "@/utils/index";
import { tz } from "@date-fns/tz";
import { parseISO } from "date-fns";
import {
    type Dispatch,
    type ReactNode,
    createContext,
    useContext,
    useMemo,
    useReducer,
} from "react";
import { v4 as uuidv4 } from "uuid";

type NewCart = { type: "newCart"; tenantId: string };
type ResetCart = { type: "resetCart"; tenantId: string };
type SetCartItems = {
    type: "setCartItems";
    tenantId: string;
    categoryId: string | undefined;
    slots: Slot[];
    addOns: AddOn[];
};
type ServiceMode = "DAILY_SERVICE" | "HOURLY_SERVICE";
type Slot = {
    serviceMode: ServiceMode;
    time: dayjs.Dayjs;
    timeDateFns: Date;
    duration: number;
    resourceId: string;
    serviceId: string;
    resourceName: string;
    price: number;
    deposit: number;
    expiry?: dayjs.Dayjs;
    expiryDateFns?: Date;
    startTime?: string;
    endTime?: string;
    addons: BookingAddOn[];
};

type ToggleSlotAddOn = {
    type: "toggleSlotAddOn";
    tenantId: string;
    serviceId: string;
    resourceId: string;
    time: Date;
    duration: number;
    addOnId: string;
    name: string;
    quantity: number;
    price: number;
};
type SetSlots = { type: "setSlots"; tenantId: string; slots: Slot[] };
type ToggleSlot = { type: "toggleSlot"; tenantId: string; slot: Slot };
type AddOn = {
    key: string;
    date: dayjs.Dayjs;
    dateDateFns: Date;
    uid: string | undefined;
    quantity: number | undefined;
    name: string | undefined;
    price: number | undefined;
};

type BookingAddOn = {
    uid: string;
    name: string;
    quantity: number;
    price: number;
};
type SetAddOns = { type: "setAddOns"; tenantId: string; addOns: AddOn[] };
type SetPromocode = {
    type: "setPromocode";
    tenantId: string;
    promocode?: string;
    discount?: number;
};
type Cart = {
    tenantId: string;
    categoryId?: string;
    slots: Slot[];
    addOns: AddOn[];
    promocode?: string;
    discount?: number;
};
type CartAction =
    | NewCart
    | ResetCart
    | SetCartItems
    | SetSlots
    | ToggleSlot
    | SetAddOns
    | SetPromocode
    | ToggleSlotAddOn;

type CartDispatch = Dispatch<CartAction>;
type CartState = { carts: Cart[] };

const CartContext = createContext<
    { state: CartState; dispatch: CartDispatch } | undefined
>(undefined);

const getNewAddOns = (addOns: AddOn[], slots: Slot[]): AddOn[] => {
    const uniqueNewSlotDates = unique(
        slots.map((s) => s.time.startOf("d").toISOString()),
    );
    const uniqueNewAddOnDates = unique(addOns.map((a) => a.date.toISOString()));
    const addOnDatesToAdd = uniqueNewSlotDates.filter(
        (s) => !uniqueNewAddOnDates.includes(s),
    );
    const newAddOns = addOns;
    for (const d of addOnDatesToAdd) {
        newAddOns.push({
            key: uuidv4(),
            date: dayjs(d).tz(),
            dateDateFns: parseISO(d, { in: tz(tzKL) }),
            uid: undefined,
            quantity: 1,
            name: undefined,
            price: undefined,
        });
    }
    // TODO: we already have a for loop above, can filter right away
    return newAddOns.filter((a) =>
        uniqueNewSlotDates.includes(a.date.toISOString()),
    );
};

const reducer = (state: CartState, action: CartAction): CartState => {
    switch (action.type) {
        case "newCart":
            return {
                carts: [
                    ...state.carts,
                    {
                        tenantId: action.tenantId,
                        categoryId: undefined,
                        slots: [],
                        addOns: [],
                        promocode: undefined,
                    },
                ],
            };
        case "resetCart":
            return {
                carts: state.carts.map((c) =>
                    c.tenantId === action.tenantId
                        ? {
                              tenantId: c.tenantId,
                              categoryId: undefined,
                              slots: [],
                              addOns: [],
                          }
                        : c,
                ),
            };
        case "setCartItems":
            return {
                carts: state.carts.map((c) =>
                    c.tenantId === action.tenantId
                        ? {
                              tenantId: c.tenantId,
                              categoryId: action.categoryId,
                              slots: action.slots,
                              addOns: action.addOns,
                          }
                        : c,
                ),
            };
        case "toggleSlot":
            return {
                carts: state.carts.map((c) => {
                    if (c.tenantId !== action.tenantId) return c;
                    const newSlots = [...c.slots];
                    const i = newSlots.findIndex((s) =>
                        sameSlotDayjs(s, action.slot),
                    );
                    if (i < 0) newSlots.push(action.slot);
                    else newSlots.splice(i, 1);
                    return {
                        ...c,
                        slots: newSlots,
                        addOns: getNewAddOns(c.addOns, newSlots),
                    };
                }),
            };

        case "toggleSlotAddOn": {
            const cart = state.carts.find(
                (c) => c.tenantId === action.tenantId,
            );
            if (!cart) return state;

            const slot = cart.slots.find((s) => sameSlot(s, action));
            if (!slot) return state;

            const addon = slot.addons.find((a) => a.uid === action.addOnId);

            if (!addon) {
                slot.addons.push({
                    uid: action.addOnId,
                    name: action.name,
                    quantity: action.quantity,
                    price: action.price,
                });
            } else {
                addon.name = action.name;
                addon.quantity = action.quantity;
                addon.price = action.price;
            }
            // The slice is needed because there are places that expect a new instance of the
            // arrays. Since we only modify the existing instance of the arrays, without the slice,
            // those places will see that its the same instance, not knowing stuff inside changed.
            slot.addons = slot.addons.slice();
            cart.slots = cart.slots.slice();
            return { carts: state.carts.slice() };
        }
        case "setSlots":
            return {
                carts: state.carts.map((c) =>
                    c.tenantId === action.tenantId
                        ? {
                              ...c,
                              slots: action.slots,
                              addOns: getNewAddOns(c.addOns, action.slots),
                          }
                        : c,
                ),
            };
        case "setAddOns":
            return {
                carts: state.carts.map((c) =>
                    c.tenantId === action.tenantId
                        ? {
                              ...c,
                              addOns: getNewAddOns(action.addOns, c.slots),
                          }
                        : c,
                ),
            };
        case "setPromocode":
            return {
                carts: state.carts.map((c) =>
                    c.tenantId === action.tenantId
                        ? {
                              ...c,
                              promocode: action.promocode,
                              discount: action.discount,
                          }
                        : c,
                ),
            };
    }
};

export const useCart = (): {
    state: CartState;
    dispatch: Dispatch<CartAction>;
} => {
    const context = useContext(CartContext);
    if (context === undefined) {
        throw new Error("useCart must be used within a CartProvider");
    }
    return context;
};

type CartProviderProps = { children: ReactNode };
export const CartProvider = ({ children }: CartProviderProps) => {
    const [state, dispatch] = useReducer(reducer, { carts: [] });
    const value = useMemo(() => ({ state, dispatch }), [state]);

    return (
        <CartContext.Provider value={value}>{children}</CartContext.Provider>
    );
};

const sameSlot = (
    s: Slot,
    a: { serviceId: string; resourceId: string; time: Date; duration: number },
) =>
    s.resourceId === a.resourceId &&
    s.serviceId === a.serviceId &&
    s.timeDateFns.getTime() === a.time.getTime() &&
    s.duration === a.duration;

const sameSlotDayjs = (
    s: Slot,
    a: {
        serviceId: string;
        resourceId: string;
        time: dayjs.Dayjs;
        duration: number;
    },
) =>
    s.resourceId === a.resourceId &&
    s.serviceId === a.serviceId &&
    s.time.unix() === a.time.unix() &&
    s.duration === a.duration;
